import React from 'react';
import chevronDown from '../../assets/images/chevron-down.svg';
import Image from 'next/image';


function SimpleCentered(props: any) {

    return (
        <>
            <div className='flex my-0 text-center'>
                <div className='flex flex-wrap gap-y-10 text-center py-10 xl:py-20 px-4 md:max-w-3xl md:mx-auto justify-center'>
                    {
                        props.subheading && <p className='text-white md:text-body text-body-mbl mt-2'>{props.subheading}</p>
                    }
                    <h1 className="md:text-header text-header-mbl font-sourcesans text-center text-white md:max-w-xl">
                        {props.heading1}<span className="text-orange">{props.headingHighlight}</span>{props.heading2}
                    </h1>
                    {
                        props.description && (
                            <p className='text-white md:text-body text-body-mbl mt-2'>
                                {props.description}
                            </p>
                        )
                    }
                </div>
            </div>
            {props.downArrow !== false && <div className='flex justify-center pt-4 h-[60px]'>
                <Image className='animate-bounce' src={chevronDown} alt="see more about virtual gravity development company nepal services" />
            </div>}
        </>
    )
}

export default SimpleCentered